import React from 'react';
import '../styles/SimplePictureRecommendation.css';

const SimplePictureRecommendation = () => {
  return (
    <div className="picture-recommendation-container">
      <div className="picture-recommendation-card">
        <h2 className="picture-recommendation-title">
          Recommend a Picture for the Slideshow
        </h2>
        <p className="picture-recommendation-text">
          To recommend a picture for our slideshow, please email your image to:
        </p>
        <a
          href="mailto:jiahan.jim.liu@gmail.com"
          className="picture-recommendation-email"
        >
          jiahan.jim.liu@gmail.com
        </a>
      </div>
    </div>
  );
};

export default SimplePictureRecommendation;