import React, { useState } from 'react';
import '../styles/Photos.css';

const Photos = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [activeSection, setActiveSection] = useState('engagement');

  // In a real application, you would replace these with actual photo URLs
  const weddingPhotos = [
    { id: 1, src: '/api/placeholder/400/300', alt: 'Wedding photo 1' }, // TODO(AMY): Add a message saying that these are available after wedding.
    { id: 2, src: '/api/placeholder/400/300', alt: 'Wedding photo 2' },
    { id: 3, src: '/api/placeholder/400/300', alt: 'Wedding photo 3' },
    { id: 4, src: '/api/placeholder/400/300', alt: 'Wedding photo 4' },
  ];

  const engagementPhotos = [
    { id: 1, src: '/api/placeholder/400/300', alt: 'Engagement photo 1' }, // TODO(AMY): Add photos of us being together - change name from engagement photos - not highly private similar to FB photos
    { id: 2, src: '/api/placeholder/400/300', alt: 'Engagement photo 2' },
    { id: 3, src: '/api/placeholder/400/300', alt: 'Engagement photo 3' },
    { id: 4, src: '/api/placeholder/400/300', alt: 'Engagement photo 4' },
  ];

  const openLightbox = (photo) => {
    setSelectedImage(photo);
  };

  const closeLightbox = () => {
    setSelectedImage(null);
  };

  return (
    <div className="photos-page">
      <h1>Our Photos</h1>
      <div className="photo-section-toggle">
        <button
          className={activeSection === 'engagement' ? 'active' : ''}
          onClick={() => setActiveSection('engagement')}
        >
          Engagement Photos
        </button>
        <button
          className={activeSection === 'wedding' ? 'active' : ''}
          onClick={() => setActiveSection('wedding')}
        >
          Wedding Photos
        </button>
      </div>

      {activeSection === 'engagement' && (
        <div className="photo-section">
          <h2>Engagement Photos</h2>
          <div className="photo-grid">
            {engagementPhotos.map((photo) => (
              <div key={photo.id} className="photo-item" onClick={() => openLightbox(photo)}>
                <img src={photo.src} alt={photo.alt} />
              </div>
            ))}
          </div>
        </div>
      )}

      {activeSection === 'wedding' && (
        <div className="photo-section">
          <h2>Wedding Photos</h2>
          <div className="photo-grid">
            {weddingPhotos.map((photo) => (
              <div key={photo.id} className="photo-item" onClick={() => openLightbox(photo)}>
                <img src={photo.src} alt={photo.alt} />
              </div>
            ))}
          </div>
        </div>
      )}


      {selectedImage && (
        <div className="lightbox" onClick={closeLightbox}>
          <img src={selectedImage.src} alt={selectedImage.alt} />
          <button className="close-button" onClick={closeLightbox}>&times;</button>
        </div>
      )}
    </div>
  );
};

export default Photos;