import React from 'react';
import '../styles/Home.css';

const Home = () => {
  return (
    <div className="home">
      <div className="home-content">
        <div className="grid-container">
          <div className="hero-image">
            <img src="/images/Home.jpg" alt="Jim and Amy's Wedding" />
          </div>
          <div className="wedding-details">
            <p>Date: March 9th, 2025</p>
            <p>Time: 5:00 PM - 10:00 PM</p>
            <p>Location: The Westin at the Woodlands</p>
            <p>Address: 2 Waterway Square Pl, The Woodlands, TX 77380</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;