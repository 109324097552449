import React from 'react';
import GitInfo from 'react-git-info/macro';
import '../styles/Footer.css';

const Footer = () => {
  let commitHash = 'Unknown';

  try {
    const gitInfo = GitInfo();
    commitHash = gitInfo.commit.hash || 'Unknown';
  } catch (error) {
    console.error('Failed to get Git info:', error);
    // You could also use a more user-friendly error handling here if needed
  }

  return (
    <footer>
      <p>&copy; 2024 Jim and Amy's Wedding. All rights reserved.</p>
      <p>Version: {commitHash}</p>
    </footer>
  );
};

export default Footer;
