import React from 'react';
import '../styles/Travel.css';

function Travel() {
  return (
    <div className="travel">
      <h1>Travel & Accommodations</h1>

      <section className="accommodations">
        <h2>Where to Stay</h2>
        <div className="hotel">
          <h3>Westin at the Woodlands</h3>
          <p>Discount rates available for wedding guests.</p>
          <p>Address: 2 Waterway Square Pl, The Woodlands, TX 77380</p>
          <a href="https://www.marriott.com/en-us/hotels/iahwi-the-westin-at-the-woodlands/overview/" className="button">Westin's Website</a>
        </div>
        <div className="hotel">
          <h3>Airbnb</h3>
          <p>If you are interested in sharing Airbnb with other young adult guests, contact Jim through messaging or at jiahan.jim.liu@gmail.com for more information.</p>
        </div>
      </section>

      <section className="transportation">
        <h2>Getting Here</h2>
        <div className="transport-option">
          <h3>By Air</h3>
          <p>The nearest airport is George Bush Intercontinental Airport (IAH), located 21 miles from the venue.</p>
        </div>
        <div className="transport-option">
          <h3>Parking</h3>
          <p>Ample parking is available at the venue.</p>
        </div>
        <div className="transport-option">
          <h3>By Car</h3>
          <p>Westin Hotel at the Woodlands is a 2.5 hours drive from Austin or 3.5 hours drive from Dallas.</p>
        </div>
      </section>
    </div>
  );
}

export default Travel;