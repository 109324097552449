import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import RSVP from './components/RSVP';
import Schedule from './components/Schedule';
import OurStory from './components/OurStory';
import SimplePictureRecommendation from './components/SimplePictureRecommendation';
import Travel from './components/Travel';
import ThingsToDo from './components/ThingsToDo';
import Photos from './components/Photos';
import './styles/global.css';

function App() {
  return (
    <Router>
      <div className="container">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/rsvp" element={<RSVP />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/our-story" element={<OurStory />} />
            <Route path="/recommend-picture" element={<SimplePictureRecommendation />} />
            <Route path="/travel" element={<Travel />} />
            <Route path="/things-to-do" element={<ThingsToDo />} />
            <Route path="/photos" element={<Photos />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;