import React from 'react';
import '../styles/OurStory.css';

const OurStory = () => {
  const storyEvents = [
    { date: 'September 19, 2023', title: 'We Met', description: 'We met through our parents! Amy was in Seattle and Jim was in Mountain View.' },
    { date: 'November 24, 2023', title: 'First Date', description: 'Our first date was getting lunch, hanging out at a park, and playing badminton.' },
    { date: 'February 14, 2024', title: 'The Proposal', description: 'Jim proposed during a romantic Valentine\'s Day dinner.' },
    { date: 'Feb 17, 2024', title: 'Move to Seattle', description: 'Jim moves to Seattle!' },
    { date: 'May 15, 2024', title: 'Marriage Certificate', description: 'Jim and Amy are officially married!' },
    { date: 'March 9, 2025', title: 'The Wedding', description: 'Jim and Amy celebrate wedding with family and friends.' },
  ];

  return (
    <div className="our-story">
      <h1>Our Story</h1>
      <p className="intro">
        From a chance meeting to a lifetime of love, here's the story of how we found each other
        and decided to spend our lives together.
      </p>
      <div className="timeline">
        {storyEvents.map((event, index) => (
          <div key={index} className={`event ${index % 2 === 0 ? 'left' : 'right'}`}>
            <div className="event-content">
              <div className="event-date">{event.date}</div>
              <h3>{event.title}</h3>
              <p>{event.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurStory;