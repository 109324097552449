import React from 'react';
import '../styles/ThingsToDo.css';

function ThingsToDo() {
  const categories = [
    {
      name: "Parks and Landmarks",
      activities: [
        { name: "NASA Space Center", description: "Mission Control for Nasa.", duration: "Day" },
        { name: "Kemah Boardwalk", description: "Resturants and Amusements next to the water.", duration: "Day" },
        { name: "Moody Gardens", description: "Aquarium Exhibit, Rainforest Exhibit, Science Exhibit", duration: "Day" }
      ]
    },
    {
      name: "Food",
      activities: [
          { name: "Corkscrew BBQ", description: "Texas BBQ", Location: "Spring" },
          { name: "Schilleci's New Orleans Kitchen", description: "Cajun Food", Location: "Woodlands" },
          { name: "Tim Ho Wan", description: "Dimsum", Location: "Katy" },
      ]
    },
    {
      name: "Shopping",
      activities: [
        { name: "Galleria", description: "Shopping mall in downtown Houston with 400 stores/resturants", duration: "3-4 Hours" },
        { name: "Woodlands Mall", description: "Shopping mall close to wedding venue", duration: "2-3 Hours" }
      ]
    }
  ];

  return (
    <div className="things-to-do">
      <h1>Things To Do in Houston</h1>
      <p className="intro">Make the most of your visit to Houston! Here are some activities and attractions we recommend:</p>
      
      {categories.map((category, index) => (
        <section key={index} className="category">
          <h2>{category.name}</h2>
          <div className="activities">
            {category.activities.map((activity, actIndex) => (
              <div key={actIndex} className="activity">
                <h3>{activity.name}</h3>
                <p>{activity.description}</p>
                <ul className="details">
                  {activity.hours && <li><strong>Hours:</strong> {activity.hours}</li>}
                  {activity.duration && <li><strong>Duration:</strong> {activity.duration}</li>}
                  {activity.location && <li><strong>Location:</strong> {activity.location}</li>}
                </ul>
              </div>
            ))}
          </div>
        </section>
      ))}
    </div>
  );
}

export default ThingsToDo;