import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'lucide-react';
import '../styles/Header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 968);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 968);
      if (window.innerWidth > 968) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="header-content">
        <h1 className="couple-names">
          <span className="name">Amy </span>
          <span className="ampersand">&amp;</span>
          <span className="name"> Jim</span>
        </h1>

        {isMobile && (
          <button
            className="hamburger-menu"
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            <Menu size={36} />
          </button>
        )}

        <nav className={isMobile && !isMenuOpen ? 'hidden' : ''}>
          <ul>
            {[
              { to: "/", text: "Home" },
              { to: "/our-story", text: "Our Story" },
              { to: "/rsvp", text: "RSVP" },
              { to: "/schedule", text: "Schedule" },
              { to: "/travel", text: "Travel" },
              { to: "/things-to-do", text: "Things To Do" },
              { to: "/photos", text: "Photos" },
              { to: "/recommend-picture", text: "Recommend Slideshow Picture" }
            ].map((link, index) => (
              <li key={index}>
                <Link
                  to={link.to}
                  onClick={() => isMobile && setIsMenuOpen(false)}
                >
                  {link.text}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;