import React from 'react';
import '../styles/Schedule.css';

const Schedule = () => {
  return (
    <div className="schedule">
      <h2>Wedding Day Schedule</h2>
      <h3>5pm-10pm, March 9th, 2025</h3>
      <ul className="schedule-list">
        <li>5pm-6pm Ceremony</li>
        <li>
          6pm-10pm Reception
          <ul>
            <li>Dinner</li>
            <li>Speeches</li>
            <li>Picture Slideshow</li>
            <li>Dance</li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Schedule;